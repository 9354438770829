var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":'Industry of work performed or served'}},[_c('b-form-checkbox-group',{staticClass:"gfb-radio-container",attrs:{"id":"checkbox-group-1","options":_vm.industryOptions,"value-field":"id","text-field":"name"},model:{value:(_vm.fields.industry),callback:function ($$v) {_vm.$set(_vm.fields, "industry", $$v)},expression:"fields.industry"}})],1),_c('b-form-group',{attrs:{"label":"What is your EMR?"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fields.emr),expression:"fields.emr"}],staticClass:"form-control emr-input",attrs:{"type":"number","placeholder":"1.50","step":"0.01","min":"0.00","max":"6.00"},domProps:{"value":(_vm.fields.emr)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.fields, "emr", $event.target.value)}}})]),_c('b-form-group',{class:[
      'gfb-radio-container',
      'required',
      { 'pill-error': _vm.errors.oshaCertification } ],attrs:{"label":'Do you hold an OSHA certification?'}},[_c('b-form-radio-group',{attrs:{"options":_vm.oshaCertificationOptions},model:{value:(_vm.fields.oshaCertification),callback:function ($$v) {_vm.$set(_vm.fields, "oshaCertification", $$v)},expression:"fields.oshaCertification"}},[_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errors.oshaCertification}},[_vm._v(_vm._s(_vm.errors.oshaCertification))])],1)],1),_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"label":"Is your company licensed?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.trueFalse},model:{value:(_vm.fields.provider.licensed),callback:function ($$v) {_vm.$set(_vm.fields.provider, "licensed", $$v)},expression:"fields.provider.licensed"}})],1),(_vm.fields.provider.licensed)?_c('text-input',{attrs:{"label":"License Type","hasError":_vm.errors.provider && _vm.errors.provider.licenseType,"errMsg":_vm.errors.provider && _vm.errors.provider.licenseType
        ? _vm.errors.provider.licenseType
        : ''},model:{value:(_vm.fields.provider.licenseType),callback:function ($$v) {_vm.$set(_vm.fields.provider, "licenseType", $$v)},expression:"fields.provider.licenseType"}}):_vm._e(),(_vm.fields.provider.licensed)?_c('text-input',{attrs:{"label":"License Number","hasError":_vm.errors.provider && _vm.errors.provider.licenseNumber,"errMsg":_vm.errors.provider && _vm.errors.provider.licenseNumber
        ? _vm.errors.provider.licenseNumber
        : ''},model:{value:(_vm.fields.provider.licenseNumber),callback:function ($$v) {_vm.$set(_vm.fields.provider, "licenseNumber", $$v)},expression:"fields.provider.licenseNumber"}}):_vm._e(),_c('b-form-group',{staticClass:"gfb-radio-container",attrs:{"label":"Does your company carry the required minimums for General Liability and Worker’s Compensation insurance?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesNo},model:{value:(_vm.fields.carryWorkersCompGeneralLiableMin),callback:function ($$v) {_vm.$set(_vm.fields, "carryWorkersCompGeneralLiableMin", $$v)},expression:"fields.carryWorkersCompGeneralLiableMin"}})],1),_c('b-form-group',{attrs:{"label":"Please list any industry organizations or \n      affiliations you are a member of"}},[_c('CustomMultiSelect',{attrs:{"selectOptions":_vm.fields.affiliations,"selectedList":_vm.fields.affiliations,"selectLabel":"Add affiliation","tagFlag":true},on:{"handle-tag":_vm.handleTag,"remove-option":_vm.removeAffiliation,"select-option":_vm.selectAffiliation,"update-selectd":_vm.updateAffiliations}})],1),_c('b-form-group',{attrs:{"label":"Briefly describe your company, your mission, specialized work"}},[_c('small',{staticClass:"pr-5 mb-3 d-block"},[_vm._v("And the specific services you provide to the construction/skilled trades industry in detail")]),_c('b-form-textarea',{attrs:{"placeholder":"Description","rows":"6","max":"250"},model:{value:(_vm.fields.description),callback:function ($$v) {_vm.$set(_vm.fields, "description", $$v)},expression:"fields.description"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }