<template>
  <div class="dropdown-container">
    <v-select
      :item-text="optionLabel"
      :item-value="optionValue"
      :class="computedClasses"
      v-model="selected"
      :items="items"
      :label="title"
      outlined
      :error="isInValid"
      :error-messages="errMsg"
      :menu-props="{ value: menuVisible, attach: 'parent' }"
      @change="handleSelection"
      @blur="menuVisible = false"
      @focus="menuVisible = true"
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "form-drop-down",
  props: {
    title: {
      type: String,
      default: "Select option",
    },
    isInValid: {
      type: Boolean,
      default: false,
    },
    classNames: {
      type: [String, Array],
      default: "",
    },
    selected: {
      type: String,
      default: "",
    },
    errMsg: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    optionValue: {
      type: String,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menuVisible: false,
    }
  },
  computed: {
    computedClasses() {
      return [
        "state-select",
        "pr-1",
        this.isInValid ? "invalid-select" : "",
        ...(Array.isArray(this.classNames)
          ? this.classNames
          : [this.classNames]),
      ]
        .filter(Boolean)
        .join(" ")
    },
  },
  methods: {
    handleSelection(value) {
      this.$emit("update-value", value)
      this.menuVisible = false
    },
  },
}
</script>
<style lang="scss" scoped>
.state-select {
  fieldset {
    top: 0;
    bottom: 0;
    box-shadow: unset;
  }
}
</style>
