<template>
  <div>
    <b-form-group
      label="Are you a material vendor?"
      :class="[
        'gfb-radio-container',
        'required',
        { 'pill-error': errors.provider && errors.provider.isVendor },
      ]"
    >
      <b-form-radio-group
        v-model="fields.provider.isVendor"
        :options="trueFalse"
        :aria-invalid="!(errors.provider && errors.provider.isVendor)"
      >
        <b-form-invalid-feedback
          :state="!(errors.provider && errors.provider.isVendor)"
          >{{
            errors.provider && errors.provider.isVendor
          }}</b-form-invalid-feedback
        >
      </b-form-radio-group>
    </b-form-group>
    <b-form-group
      v-if="fields.provider.isVendor"
      label="Please select all trade categories  most relevant to the products, equipment, 
            or materials you sell *"
      :class="[
        'required',
        { 'pill-error': errors && errors.tradesRelatedToProduct },
      ]"
    >
      <CustomMultiSelect
        :selectOptions="categoryOptions"
        :selectedList="fields.tradesRelatedToProduct"
        selectLabel="Search or select category"
        @update-selected="updateTrades"
      ></CustomMultiSelect>
      <b-form-invalid-feedback
        :state="!(errors && errors.tradesRelatedToProduct)"
        >{{ errors && errors.tradesRelatedToProduct }}</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="fields.provider.isVendor"
      label="Does your business provide installation labor services for the products or materials you sell?"
      :class="[
        'gfb-radio-container',
        'required',
        'mt-4',
        { 'pill-error': errors && errors.providesInstallLaborServices },
      ]"
    >
      <b-form-radio-group
        v-model="fields.providesInstallLaborServices"
        :options="trueFalse"
        :aria-invalid="!(errors && errors.providesInstallLaborServices)"
        ><b-form-invalid-feedback
          :state="!(errors && errors.providesInstallLaborServices)"
          >{{
            errors && errors.providesInstallLaborServices
          }}</b-form-invalid-feedback
        ></b-form-radio-group
      >
    </b-form-group>
    <b-form-group v-if="!fields.provider.isVendor">
      <template #label>
        <p class="info-warning-text">
          Please select only the trade categories specific to the labor services
          you perform using your own employee labor and NOT subcontracted labor
        </p>
        <p class="info-warning-text">
          If you provide other services with subcontracted labor - please select
          “General Contractor”
        </p>
      </template>
      <CustomMultiSelect
        :selectOptions="categoryOptions"
        :selectedList="fields.selfPerformWorkCategories"
        selectLabel="Search or select category"
        @update-selected="updateCategories"
      ></CustomMultiSelect>
    </b-form-group>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus"
import options from "@/utils/options"
import { deNullifyErrors, normalize } from "@/utils/validations"
import CustomMultiSelect from "@/components/CustomMultiSelect"
export default {
  name: "company-profile-business-services",
  components: {
    CustomMultiSelect,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      trueFalse: options.trueFalse,
    }
  },
  computed: {
    categoryOptions() {
      return [...options.categoryOptions, "General Contractor"].sort((a, b) =>
        a.localeCompare(b)
      )
    },
  },
  mounted() {
    EventBus.$on("validate-company-business-services", () => {
      this.validateBusinessServices()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("validate-company-business-services")
  },
  methods: {
    removeTrade(option) {
      this.fields.tradesRelatedToProduct =
        this.fields.tradesRelatedToProduct.filter(
          (category) => category !== option
        )
    },
    addTrade(option) {
      this.fields.tradesRelatedToProduct.push(option)
    },
    updateTrades(selected) {
      this.fields.tradesRelatedToProduct = selected
    },
    removeSelfPerformCategory(option) {
      this.fields.selfPerformWorkCategories =
        this.fields.selfPerformWorkCategories.filter(
          (category) => category !== option
        )
    },
    addSelfPerformCategory(option) {
      this.fields.selfPerformWorkCategories.push(option)
    },
    updateCategories(selected) {
      this.fields.selfPerformWorkCategories = selected
    },
    validateBusinessServices() {
      let isVendor = this.fields.provider.isVendor
      const data = normalize(this.fields)
      let errors = {
        provider: {
          isVendor: isVendor == undefined ? "This is required" : null,
        },
        tradesRelatedToProduct: isVendor
          ? !data.tradesRelatedToProduct
            ? "This is required"
            : null
          : null,
        providesInstallLaborServices: isVendor
          ? data.providesInstallLaborServices == undefined
            ? "This is required"
            : null
          : null,
      }
      this.errors = errors
      this.$emit(
        "proceed-next",
        Object.keys(deNullifyErrors(errors)).length > 0 ? false : true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.info-warning-text {
  font-weight: 600;
  font-size: 18px;
}
</style>
